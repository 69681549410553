// Main Colors
$black: #111;
$white: #fff;
$lightGrey: #e0e0e0;
$grey: #b2ada6;
$darkGrey: #1c1c1e;
$placeholderGrey: #575757;
$gold: #977c50;
$yellow: #fce883;
$red: #f44336; // Material UI `palette.error.main`
$green: #4caf50; // Material UI `palette.success.main`

// Custom business palettes
$teal: #294041;
$tealDark: #001f22;
$tealLight: #214141;
$pinkishGrey: #dcd3bc;
$lightGold: #ceb66e;

// Export SCSS variables as modules to import in TS without duplicating hex values
:export {
  black: $black;
  white: $white;
  lightGrey: $lightGrey;
  grey: $grey;
  darkGrey: $darkGrey;
  placeholderGrey: $placeholderGrey;
  gold: $gold;
  yellow: $yellow;
  red: $red;
  green: $green;
  teal: $teal;
  tealDark: $tealDark;
  tealLight: $tealLight;
  pinkishGrey: $pinkishGrey;
  lightGold: $lightGold;
}
