@import 'src/theme/theme.module.scss';

.landing {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  overflow: hidden;
}

.registrationForm {
  position: relative;

  .logo {
    height: 70px;
    width: 100px;
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0;
    min-height: unset;
  }

  .verificationCodeInput {
    flex-grow: 1;
    max-width: 400px;

    input {
      text-transform: uppercase;
    }
  }

  .landingSubmitButton {
    height: 56px;
    min-width: 160px;
    font-size: 12px;
    text-align: center;
    padding: 10px 30px 8px;
    margin-left: 10px;
    font-weight: 800;
  }

  .submitButton {
    font-size: 12px;
    align-self: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    padding: 10px 30px;
    display: block;
    font-weight: 800;
    width: 100%;
  }

  .info {
    font-size: 16px;
    padding-top: 10px;
    margin-top: 0;
    line-height: 1.3em;
    text-transform: uppercase;
    padding-bottom: 100px;
    font-weight: 300;
  }

  .helper {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;

    a {
      color: $white;
    }
  }

  .wrapper {
    margin: 0 auto;
    padding: 0 15%;
    justify-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 48px;
      line-height: 48px;
      margin: 100px 0 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    .form {
      justify-content: center;
      display: flex;
      max-width: 85vw;
    }
  }

  .headerWrapper {
    max-width: 600px;
    padding: 0 24px;
  }

  .headerSubtitle {
    margin: 20px 0;
  }

  .headerText {
    padding: 0 0 30px;
    font-weight: 300;
  }

  .paymentForm {
    max-width: 500px;
    min-width: 250px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .loadingRing {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .steps {
    .step {
      display: flex;
      flex-direction: row;

      .circle {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        display: block;
        margin: 0 auto;
        background: $white;
        color: $grey;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .formItem {
    margin-bottom: 20px;

    &:global(.MuiGrid-grid-xs-2),
    &:global(.MuiGrid-grid-xs-4),
    &:global(.MuiGrid-grid-xs-8),
    &:global(.MuiGrid-grid-xs-5) {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }

  :global(.MuiAutocomplete-clearIndicator) {
    display: none;
  }

  :global(.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon) {
    :global(.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']) {
      padding: 9px 30px 9px 9px;
    }

    :global(.MuiAutocomplete-input) {
      padding: 10px 4px;
    }
  }

  @media screen and (max-width: 850px) {
    .wrapper {
      .title {
        font-size: 38px;
        line-height: 38px;
      }
    }

    .headerSubtitle {
      margin: 0;
    }

    .headerText {
      padding: 0 0 10px;
    }

    .details {
      padding: 30px 0;
    }

    .info {
      padding-bottom: 80px;
    }

    .form {
      flex-direction: column;
    }

    .landingSubmitButton {
      width: initial;
      margin: 10px 0;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      padding: 0 30px;

      .title {
        font-size: 26px;
        line-height: 26px;
      }
    }

    .info {
      padding-bottom: 40px;
      font-size: 14px;
    }

    .formItem {
      margin-bottom: 10px;

      &:global(.MuiGrid-grid-xs-2),
      &:global(.MuiGrid-grid-xs-4),
      &:global(.MuiGrid-grid-xs-8),
      &:global(.MuiGrid-grid-xs-5) {
        flex-basis: 100%;
        max-width: 100%;

        &:not(:last-child) {
          padding-right: 0;
        }
      }
    }

    .registrationForm {
      :global(.MuiInputBase-input) {
        padding: 10px;
      }

      :global(.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon) {
        :global(.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']) {
          padding: 0 30px 0 4px;
        }
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
}

.logo {
  height: 30vmin;
  min-height: 150px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../../assets/logos/Velocity_logo.png);
  z-index: 1;
}

.taglineVideo {
  height: 30vw;
  min-height: 250px;
  margin: -110px 0;
  z-index: 0;
}

.hiddenTagline {
  color: $black;
}

.ldsRing {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 40px;
  height: 40px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    margin: 8px;
    border: 3px solid $white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $white transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.errorMessage {
  display: block;
  color: $red;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.addressSuggestion {
  margin: 5px 0;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
}

.manualSuggestion {
  font-weight: 400;
  font-style: italic;
}

.checkoutForm {
  background-color: $white;
  border-radius: 10px;
  padding: 0 20px;
  display: flex;

  .cardFormItem {
    display: inline-block;
    height: 55px;
    min-width: 250px;
    flex: 5 1;
  }

  .expiryDateCVV {
    flex: 1;
    display: inline-block;
    max-width: 80px;
    height: 55px;
  }
}

.formWrapper {
  background-color: $lightGrey;
}
