@import 'src/theme/theme.module.scss';

.fullHeight {
  height: 100%;
}

.registrationForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    top: 70px;
    width: 75px;
    height: 55px;
    left: 50%;
    transform: translateX(-50%);
  }

  .thankYou {
    display: flex;
    height: 100%;
  }

  .verificationCodeInput {
    margin-bottom: 30px;
    width: 300px;

    input {
      text-transform: none;
      font-size: 16px;
    }
  }

  .landingSubmitButton {
    height: 41px;
    font-size: 13px;
    padding: 13px;
    margin: 0;
    font-weight: 400;
    width: 300px;
  }

  .info {
    font-size: 16px;
    text-align: center;
    text-transform: none;
    margin: 20px 0 0 0;
    padding: 0;
    font-weight: 350;
    opacity: 0.65;
  }

  .helper {
    margin: 10px 0 50px 0;
  }

  .pricing {
    text-align: left;
    opacity: 0.65;
  }

  .terms {
    opacity: 0.65;
  }

  .wrapper {
    .title {
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      font-weight: 350;
      margin: 0;
      text-transform: none;
    }

    .form {
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
    }
  }

  .headerWrapper {
    .title {
      margin: 200px 0 10px;
    }
  }

  .headerSubtitle {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    opacity: 0.65;
    font-weight: 350;
  }

  .formItem {
    & :global(.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child) {
      padding-left: 13px;
    }

    & :global(.MuiAutocomplete-popupIndicator) {
      color: $placeholderGrey;
    }

    & :global(.MuiInputAdornment-root .MuiIconButton-label) {
      color: $placeholderGrey;
    }
  }

  .selectPlaceholder {
    & :global(.MuiSelect-select.MuiSelect-select) {
      color: $placeholderGrey;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      .title {
        font-size: 26px;
      }
    }
  }
}
