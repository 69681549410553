@import 'src/theme/theme.module.scss';

.registrationForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 27px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: 3.24px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: GillSansStd;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.4px;
    text-align: center;
    color: #fff;
  }

  .info {
    font-size: 20px;
    text-align: center;
    text-transform: none;
    line-height: 1.5;
    margin: 20px 0;
    padding: 0;
    font-weight: 400;
    opacity: 0.65;
    white-space: pre-wrap;
  }

  .asterisk {
    font-size: 11px;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #444;
  }

  .thankYou {
    background-color: #f2f2f2;
    padding: 4rem 0 10rem;
  }

  .logo {
    position: relative;
    top: initial;
    left: initial;
    height: 50px;
  }

  .header {
    background-color: white;
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videoWrapper {
    width: 100%;
    margin: 0;
    background-color: #111;
    display: flex;
    place-items: center;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: 769px) {
      height: 700px;
    }
  }

  .heroWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    height: 500px;

    @media screen and (min-width: 769px) {
      height: 700px;
    }

    .heroContent {
      color: white;
      z-index: 1;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info {
        max-width: 500px;
        margin: 0;
        opacity: 1;

        @media screen and (max-width: 768px) {
          padding: 0 20px;
        }
      }
    }

    .heroAdornment {
      position: absolute;
      width: 100%;
      z-index: 1;
      color: white;
      bottom: 0;
      h2 {
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
        margin-bottom: 70px;
      }

      button {
        width: 60px;
        height: 60px;
        min-width: initial;
      }
    }

    .heroBGWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
    }
  }

  .contentSection {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 0;

    > * {
      width: 100%;
    }

    @media screen and (min-width: 769px) {
      > * {
        width: 40%;
      }

      .contentImageWrapper {
        width: 60%;
      }
    }

    .innerWrapper {
      padding: 20px;

      @media screen and (min-width: 769px) {
        padding: 50px;
      }
    }

    :last-child {
      .innerWrapper {
        padding: 0 20px;

        @media screen and (max-width: 768px) {
          padding: 20px;
        }
      }
    }

    .contentImageWrapper {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .content {
      .subtitle,
      .info {
        margin: 0;

        @media screen and (min-width: 769px) {
          text-align: left;
        }
      }

      .subtitle {
        margin-bottom: 10px;
      }
    }
  }

  .verificationCodeInput {
    margin-bottom: 15px;
    width: 300px;

    input {
      text-align: center;
    }
  }

  .landingSubmitButton {
    height: 41px;
    font-size: 13px;
    padding: 13px;
    margin-left: 0;
    font-weight: 400;
  }

  .helper {
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 40px;

    a {
      color: #111;
      font-weight: bold;
    }
  }

  .terms {
    opacity: 0.65;
  }

  .submitButton {
    font-family: GillSansStd-Light;
    font-weight: 300;
  }

  .wrapper {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: initial;

    .innerWrapper {
      max-width: 600px;
      padding-top: 100px;
      padding-bottom: 100px;

      @media screen and (max-width: 768px) {
        padding: 50px 20px;
      }
    }

    .form {
      margin-top: 50px;
      flex-direction: column;
      align-items: center;
    }
    .title {
      font-family: GillSansStd-Light;
      font-weight: 300;
      font-size: 24px;
      color: #6d6e71;
    }
  }

  .headerWrapper {
    .innerWrapper {
      padding: 100px 0 0;
    }

    .title {
      font-size: 27px;
      margin: 0 0 10px;
    }
  }

  .headerSubtitle {
    white-space: pre-wrap;
    text-align: center;
    font-size: 17px;
    line-height: 1.24;
    opacity: 0.65;
    font-weight: 400;
  }

  .details {
    min-height: initial;
    background-color: #f2f2f2;
    padding: 30px 0 100px;

    > .helper {
      font-size: 24px;
    }
  }

  .formItem {
    & :global(.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child) {
      padding: 0;
    }

    :global(.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon) {
      :global(.MuiAutocomplete-input) {
        padding: 5px;
      }
    }

    & :global(.MuiAutocomplete-popupIndicator) {
      color: $placeholderGrey;
    }

    & :global(.MuiInputAdornment-root .MuiIconButton-label) {
      color: $placeholderGrey;
    }
  }

  .selectPlaceholder {
    & :global(.MuiSelect-select.MuiSelect-select) {
      color: $placeholderGrey;
    }
  }

  @media screen and (max-width: 850px) {
    .wrapper {
      .title {
        font-size: 35px;
        line-height: 35px;
        margin: 100px 0 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .title {
      font-size: 25px;
      line-height: 25px;
      margin: 100px 0 10px;
    }

    .headerWrapper {
      .title {
        font-size: 20px;
        margin: 50px 0 10px;
      }
    }

    .details {
      padding-top: 0;
    }

    .helper {
      margin-bottom: 30px;
    }
  }

  .whatNext {
    text-align: left;

    h3 {
      text-transform: uppercase;
      font-size: 14px;
    }

    p {
      font-size: 11px;
    }

    a {
      font-weight: bold;
    }
  }

  .errorMessage {
    text-align: center;
  }
}
