html,
body {
  height: 100%;
  background-color: #0d0d0d;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Fira Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#concierge-root > div {
  width: 100%;
}

.web-scrubber[type='range'] {
  background: transparent;
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0;
  /* iOS */
}

.web-scrubber::-webkit-slider-runnable-track {
  background: transparent;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
.web-scrubber::-webkit-slider-thumb {
  background: white;
  -webkit-appearance: none;
  width: 15px;
  /* 1 */
  height: 15px;
  border: 1px solid black;
  border-radius: 15px;
  /* 1 */
}

.web-scrubber::-moz-range-track {
  height: 15px;
  background: transparent;
}

.web-scrubber::-moz-range-thumb {
  background: white;
  height: 15px;
  width: 15px;
  border: 1px solid black;
  box-sizing: border-box;
}

.web-scrubber::-ms-fill-lower {
  background: transparent;
}

.web-scrubber::-ms-thumb {
  background: white;
  border: 1px solid black;
  height: 15px;
  width: 15px;
  box-sizing: border-box;
}

.web-scrubber::-ms-ticks-after {
  display: none;
}

.web-scrubber::-ms-ticks-before {
  display: none;
}

.web-scrubber::-ms-track {
  background: transparent;
  color: transparent;
  height: 15px;
  border: none;
}

.web-scrubber::-ms-tooltip {
  display: none;
}

.web-scrubber-progress[value] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: 5px;
  z-index: -1;
}

.web-scrubber-progress[value]::-webkit-progress-bar {
  border-radius: 5px;
  background-color: #555;
}

.web-scrubber-progress[value]::-webkit-progress-value {
  background-color: #999;
  border-radius: 5px 0 0 5px;
}

/* Calendar view web app custom styles */
.Cal__Weekdays__day {
  color: #aaa;
}

.Cal__Container__wrapper .Cal__Month__rows {
  background: white;
}

.Cal__Month__row .Cal__Day__root.Cal__Day__disabled {
  color: #ddd;
}
